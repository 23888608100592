<template>
  <div id="app">
    <div ref="AutoScalContainerRef">
      <div ref="DomRef" class="container1" :class="{ home: path == '/' }">
      
        <router-view />
      </div>
    </div>
  </div>
</template>

<style>
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-fs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container1 {
  width: 1920px;
  /* background-image: url(./assets/image/container_bg.png); */
  background-size: contain;
  transform-origin: top left;
  overflow-y: hidden;
}

</style>
<script>
import autoResizeScreenMixin from "@/utils/detectZoom";

export default {
  mixins: [autoResizeScreenMixin],
  components: {
 
  },
  data() {
    return {
      path: "",
    };
  },
  mounted() {
    this.path = this.$route.path;
  },
  watch: {
    // 监听路由对象$route的变化
    $route: {
      handler: function (to, from) {
        // 路由发生变化时执行的代码
        this.path = to.path;
        console.log("Route changed from", from.path, "to", to.path);
      },
      // 设置为深度监听
      deep: true,
    },
  },
};
</script>
