<template>
  <div>
    <div>
      <div
        id="map"

      ></div>
    </div>
    <div class="container">
      <div class="header">
        <img src="@/assets/image/top1.png" />
      </div>
      <div class="top-session">
        <div class="item" v-for="(item, index) in topdata" :key="index">
          <div class="captoin" :class="item.class">
            <div class="cap-left">
              <img :src="item.icon" alt="">
              {{ item.cap }}</div>
            <div class="cap-right">
              {{ item.capVal }} <span>{{ item.lable }}</span>
            </div>
          </div>
          <div class="list" :class="item.class">
            <div
              class="list-item"
              :class="item.class1"
              v-for="(sitem, sindex) in item.list"
              :key="sindex"
            >
              <div class="title">{{ sitem.title }}</div>
              <div class="value">{{ sitem.value }} {{ sitem.label }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="item">
            <div class="session left1" id="left1"></div>
          </div>
          <div class="item">
            <div class="session left2" id="left2"></div>
          </div>
          <div class="item">
            <div class="session left3" id="left3"></div>
          </div>
          <div class="item">
            <div class="session left4" id="left4"></div>
          </div>
        </div>
        <div class="content-middle" >
          <div class="select">
              <dl>
                <dt>请选择 <span></span></dt>
                <dd class="border">出租情况</dd>
                <dd>业态类型</dd>
              </dl>
          </div>
          <div class="session">
            <div class="item" v-for="item,index in middledata" :key="index">
              {{ item }}
            </div>
          </div>

        </div>
        <div class="content-right">
          <div class="item">
            <div class="session right1" id="right1"></div>
          </div>
          <div class="item">
            <div class="session right2" id="right2"></div>
          </div>
          <div class="item">
            <div class="session right3" id="right3"></div>
          </div>
          <div class="item">
            <div class="session right4" id="right4"></div>
          </div>
        </div>
      </div>
    </div>
    <home-toast @closeToast="closeToast" v-if="homeToastShow"></home-toast>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import HomeToast from "@/components/homeToast.vue";
export default {
  name: "maintenance",
  components: {
    HomeToast,
  },
  data() {
    return {
      middledata:[
      "项目名称：CBD1号写字楼"," 项目编号：CBD1"," 登记日期：2024-01-01 ","项目类型：写字楼 商铺 ","项目总面积：600㎡"," 已出租面积 400㎡","未出租面积 200㎡ ","资产数量 10", "已出租数量 5", "未出租数量 5","数量出租率 50%"
      ],
      topdata: [
        {
          cap: "总资产面积",
          capVal: " 4512.32",
          lable: "万",
          class: "double",
          class1: "one",
          icon: require("@/assets/image/icon1.png"),
          list: [
            {
              title: "已出租面积",
              value: "1222.23万",
              label: "万",
            },
            {
              title: "未出租面积",
              value: "5554.2",
              label: "万",
            },
            {
              title: "出租率",
              value: "23.56",
              label: "%",
            },
            {
              title: "年目标",
              value: "23.56",
              label: "万",
            },
          ],
        },

        {
          cap: "资产总数",
          capVal: "485",
          lable: "套",
          icon: require("@/assets/image/icon2.png"),
          list: [
            {
              title: "已出租",
              value: "145",
              label: "套",
            },
            {
              title: "未出租",
              value: "131",
              label: "套",
            },
            {
              title: "出租率",
              value: "23.56",
              label: "%",
            },
          ],
        },
        {
          cap: "客户总量",
          capVal: "657",
          lable: "个",
          icon: require("@/assets/image/icon3.png"),
          list: [
            {
              title: "已成交",
              value: "545",
              label: "个",
            },
            {
              title: "未成交",
              value: "53",
              label: "个",
            },
            {
              title: "成交率",
              value: "98.16",
              label: "%",
            },
          ],
        },
        {
          cap: "本年度签约合同",
          capVal: "157",
          icon: require("@/assets/image/icon4.png"),
          lable: "个",
          list: [
            {
              title: "总金额",
              value: "15823.23",
              label: "万元",
            },
            {
              title: "未成交",
              value: "19",
              label: "个",
            },
            {
              title: "成交率",
              value: "32",
              label: "个",
            },
          ],
        },
        {
          cap: "年度营收目标",
          capVal: "1446.57",
          icon: require("@/assets/image/icon5.png"),
          lable: "万元",
          class: "double",
          list: [
            {
              title: "已收总额",
              value: "1823.23",
              label: "万元",
            },
            {
              title: "待收总额",
              value: "2823.19",
              label: "万元",
            },
            {
              title: "待未收超期",
              value: "1123.32",
              label: "万元",
            },
            {
              title: "待收超期",
              value: "31.62",
              label: "万元",
            },
          ],
        },
        {
          cap: "年度客户意向",
          capVal: "169",
          lable: "个",
          icon: require("@/assets/image/icon6.png"),
          list: [
            {
              title: "关闭",
              value: "15823.23万元",
              label: "个",
            },
            {
              title: "转商机",
              value: "19",
              label: "个",
            },
            {
              title: "商机已签收",
              value: "32",
              label: "个",
            },
          ],
        },
      ],
      homeToastShow: false,
      tabInd: 1,
    };
  },
  mounted() {

    this.drawLine();
    this.map()
  },

  methods: {
    map() {
      AMapLoader.load({
        key: "d1b569a4b168e12da5a06d9a5aeb6703",
        version: "2.0",
        plugins: [
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
        ],

      })
      .then((AMap) => {
        const map = new AMap.Map("map", { // eslint-disable-line no-unused-vars
          layers: [
                // 卫星
                new AMap.TileLayer.Satellite(),
                // 路网
                new AMap.TileLayer.RoadNet()
            ],
          zoom: 16,
          center: [	118.12081841021336,37.15376945858527],
        });

// 标记的位置数组
// var positions = [
//     [116.069015,35.394718], // 位置1 - 经度, 纬度
//     [116.057271,35.390532],  // 位置2
//     [	116.082514,35.396637]   // 位置3
//     // 添加更多位置
// ];
 
// 创建标记
// var markers = positions.map(function(position, index) {
//     var marker = new AMap.Marker({
//         position: position,
//         map: map, // 指定将标记添加到哪个地图实例
//         title: '标记' + index // 自定义标题
//     });
//     // 可以添加更多选项，如icon等
//     return marker;
// });
 
// 将所有标记添加到地图
// markers.forEach(function(marker) {
//     marker.setMap(map);
// });

          // 进行进一步的地图操作...
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const right1 = this.$echarts.init(document.getElementById("right1"));
      const right2 = this.$echarts.init(document.getElementById("right2"));
      const right3 = this.$echarts.init(document.getElementById("right3"));
      const right4 = this.$echarts.init(document.getElementById("right4"));
      const left1 = this.$echarts.init(document.getElementById("left1"));
      const left2 = this.$echarts.init(document.getElementById("left2"));
      const left3 = this.$echarts.init(document.getElementById("left3"));
      const left4 = this.$echarts.init(document.getElementById("left4"));
      right1.setOption({
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "占比",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "65%"],
            data: [              
              { value: 26, name: "未出租: 26%" },
              { value: 74, name: "已出租: 74%" },

            ],
            itemStyle: {
              color: function (params) {
                // 定义一个颜色数组
                var colors = ["#96E5F5", "#6692e9"];
                // 返回每个扇区的颜色，可以按照其他逻辑设置颜色
                return colors[params.dataIndex % colors.length];
              },
            },
          },
        ],
      });
      left1.setOption({
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  radius: ["30%", "50%"],
  center: ["50%", "65%"],
  series: [

    {
      name: '未出租面积 2.921793万平米',
      type: 'pie',
      radius: '70%',
      labelLine: {
        length: 10
      },
      label: {
        formatter: '{a|{a}}{abg|}\n{hr|}\n \n {c} %  ',

        borderWidth: 1,
        borderRadius: 4,
        rich: {
          a: {
            color: '#fff',
            lineHeight: 22,
            align: 'center'
          },
          hr: {
            borderColor: '#fff',
            width: '100%',
            borderWidth: 1,
            height: 0
          },
          b: {
            color: '#fff',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 33
          },

        }
      },
      data: [
        { value: 13.85, name: '未出租面积 2.921793万平米' },
        { value: 86.15, name: '已出租面积 18.180715万平米' },

      ]
    },
  ]
});

      right2.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: ["#249CF9", "#1D9DF3"],

        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["23/11", "23/12","24/01", "24/02", "24/03", "24/04", "24/05", "24/06", "24/07", "24/08", "24/08", "24/10"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "万",
            min: 0,
            max: 100,
            interval: 15,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            data: [20, 40, 50, 80, 70, 10,20, 40, 50, 80, 70, 10,20, 40, 50, 80,],
            barWidth: "35%",
          },
          {
            name: "",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data:[20, 40, 50, 80, 70, 10,20, 40, 50, 80, 70, 10,20, 40, 50, 80,],
          },
        ],
      });
     
      left2.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: [ "#1D9DF3","#34C868",],

        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["23/11", "23/12","24/01", "24/02", "24/03", "24/04", "24/05", "24/06", "24/07", "24/08", "24/08", "24/10"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "个",
            min: 0,
            max:25,
            interval: 5,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [
          {
        
            type: "bar",
            data: [3, 4, 5, 8, 7, 7,5, 5, 6, 4, 2, 3,],
            barWidth: "35%",
            stack: 'a',
            name: ''
          },
          {
 
            type: "bar",
            data: [20, 17, 15, 16, 13, 10,12, 15, 13, 11, 7, 10,],
            barWidth: "35%",
            stack: 'a',
            name: ''
          },

        ],
      });
     
      left3.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: ["#1D9DF3","#8DCCFC", ],

        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["23/11", "23/12","24/01", "24/02", "24/03", "24/04", "24/05", "24/06", "24/07", "24/08", "24/08", "24/10"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "万元",
            min: 0,
            max: 100,
            interval: 15,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [
        {
        
        type: "bar",
        data: [15, 25, 55, 8, 7, 7,25, 15, 36, 55, 22, 3,],
        barWidth: "35%",
        stack: 'a',
        name: ''
      },
      {

        type: "bar",
        data: [30, 20, 25, 36, 43, 30,42, 45, 53, 20, 7, 10,],
        barWidth: "35%",
        stack: 'a',
        name: ''
      },

        ],
      });
     
      left4.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: ["#2BFFA3", "#fff"],


        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["60天内到期", "30天内到期","7天内到期", "今日", "已逾期", "已逾期超7天", "已逾期超30天", "已逾期超60天"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "万元",
            min: 0,
            max: 100,
            interval: 15,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            data: [        {
          value: 62,
          itemStyle: {
            color: '#2bffa3'
          }
        },        {
          value: 30,
          itemStyle: {
            color: '#2bffa3'
          }
        },        {
          value: 15,
          itemStyle: {
            color: '#2bffa3'
          }
        }, {
          value: 10,
          itemStyle: {
            color: '#2bffa3'
          }
        },        {
          value:25,
          itemStyle: {
            color: '#fdb628'
          }
        },        {
          value: 7,
          itemStyle: {
            color: '#fdb628'
          }
        },        {
          value: 25,
          itemStyle: {
            color: '#fdb628'
          }
        }, {
          value: 2,
          itemStyle: {
            color: '#fdb628'
          }
        },  ],
            barWidth: "35%",
          },

        ],
      });
     
      right4.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: ["#249CF9", "#1D9DF3"],

        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["23/11", "23/12","24/01", "24/02", "24/03", "24/04", "24/05", "24/06", "24/07", "24/08", "24/08", "24/10"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "万",
            min: 0,
            max: 100,
            interval: 15,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            data: [ 70, 10,20, 40, 50, 80,20, 40, 50, 10,15,5],
            barWidth: "35%",
          },
          {
            name: "",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data:[ 70, 10,20, 40, 50, 80,20, 40, 50, 10,15,5 ],
          },
        ],
      });
     
      right3.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#07DBFF",
            },
          },
        },
        color: ["#249CF9", "#1D9DF3"],

        grid: {
          //位置
          left: "10%",
          right: "4%",
          top: "40%",

          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: ["23/11", "23/12","24/01", "24/02", "24/03", "24/04", "24/05", "24/06", "24/07", "24/08", "24/08", "24/10"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
          },
          
        ],
        yAxis: [
          {
            type: "value",
            name: "个",
            min: 0,
            max: 100,
            interval: 15,
            splitLine: {
              //取消横线
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#07DBFF",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            axisLine: {
      
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              show: false,
            },
 
          },
        ],
        series: [

          {
            name: "",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data:[20, 10, 10, 20, 30, 10,20, 40, 50, 50, 20, 60],
          },
        ],
      });

    },
    closeToast() {
      this.homeToastShow = false;
    },
    tabSwitch(val) {
      this.tabInd = val;
    },
  },
};
</script>

<style lang="less" scoped>
#map {
  position: fixed;
  width: 1920px;
  height: 1080px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.container {
  .header {
    position: relative;
    z-index: 99999;
    text-align: center;
    img {
      height: 75px;
      vertical-align: top;
    }
  }
  .top-session {
    position: relative;
    z-index: 6666;
    padding: 0 8px ;

    display: flex;
    justify-content: flex-start;
    align-content: center;

    .item {
      width: 313px;
      height: 134px;
      background-image: url('../assets/image/top_bg.png') ;
      background-size: 100% 100%;
      

      margin-right: 14px;
      .captoin {
        padding-top: 7px;
        padding-bottom: 22px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.double {
          padding-bottom: 3px;
        }
        .cap-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #dbefff;
          font-size: 16px;

          img {
 
            width: 35px;
      

          }
        }
        .cap-right {
          font-weight: bold;
          color: #00d7e1;
          font-size: 28px;
          span {
            font-size: 12px;
          }
        }
      }
      .list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        &.double {
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 22px;
          .list-item:nth-child(2) {
            margin-left: 50px;
          }
        }
        .list-item {
          text-align: center;
          &.one {
            &:nth-child(4),
            &:nth-child(3) {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div{
                margin-right: 5px;
              }
            }
          }
          .title {
            color: #dbefff;
            font-size: 14px;
           
          }
          .value {
            font-size: 16px;
            color: #00d7e1;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: flex-start;

    &-left {
      width: 354px;
      margin: 0 7px;
      // background-color: aliceblue;
      position: relative;
      z-index: 9999;
      .item {
        width: 354px;
        height: 199px;
        &:nth-child(1){
          background-image: url('../assets/image/left1.png') ;
        }
        &:nth-child(2){
          background-image: url('../assets/image/left2.png') ;
        }
        &:nth-child(3){
          background-image: url('../assets/image/left3.png') ;
        }
        &:nth-child(4){
          background-image: url('../assets/image/left4.png') ;
        }

        background-size: 100% 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
        .left1{
          transform: scale(0.7);
          width: 700px;
          height: 200px;
          margin-top: 50px;
        }
        .left2,.left3,.left4{
          width: 430px;
          height: 200px;
        }
      }
    }
    &-middle {
      // position: absolute;
      // left: 0px;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 7777;
      .select{
        float: left;
        margin-top: 6px;
        margin-left: 4px;
        background: #529ace;
        border-radius: 5px;
        overflow: hidden;
        line-height: 30px;
        color: #fff;
        font-size: 16px;
        text-align: center;
        dl{
          width: 118px;
          padding-bottom: 2px;
          cursor: pointer;
          dt{
            background-image: url('../assets/image/select.png') ;
            background-size: 100% 100%; 
            height: 30px;
            box-shadow: 0 -2px 10px #fff;
            span{
              display:inline-block;
              border:8px solid #fff;
              border-bottom-color:transparent;
              border-left-color:transparent;
              border-right-color:transparent;
              vertical-align: -webkit-baseline-middle;
              margin-left: 12px;
            }
          }
          dd{
            margin: 0 13px;
            &.border{
              border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
      .session{
        float: right;
        width: 300px;
        height: 382px!important;
        background:rgba(64, 185, 223, 0.8);
        font-size: 20px;
        color: #fff;
        padding-top: 19px;
        padding-left: 35px;
        box-sizing: border-box;
        line-height: 32px;
      }
    }
    &-right {
      position: relative;
      z-index: 9999;

      width: 354px;
      margin: 0 7px;
      .item {
        width: 348px;
        height: 199px;
    

        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1){
          background-image: url('../assets/image/right1.png') ;
        }
        &:nth-child(2){
          background-image: url('../assets/image/right2.png') ;
        }
        &:nth-child(3){
          background-image: url('../assets/image/right3.png') ;
        }
        &:nth-child(4){
          background-image: url('../assets/image/right4.png') ;
        }
        background-size: 100% 100%;
        .right1{
          transform: scale(0.7);
          width: 348px;
        }
        .right2,.right3,.right4{
          width: 430px;
          height: 190px;
        }
      }
    }
    .session {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}
</style>
